import { Drawer, Empty } from 'antd';
import React from 'react';
import styles from './goodsRelateModal.less';
import { GoodsRelateModalStore } from './goodsRelateModalStore';
import { NormalProgrammeComponent, DragAndDropHOC } from 'egenie-utils';
import { observer } from 'mobx-react';
import { RenderByCondition, getStaticResourceUrl } from 'egenie-common';
import { GoodsRelateSkuModal } from './goodsRelateSkuModal/goodsRelateSkuModal';
import { GoodsItemDisplay } from './goodsItemDisplay/goodsItemDisplay';
import goodsItemDisplayStyles from './goodsItemDisplay/goodsItemDisplay.less';
import { ENUM_GOODS_TYPE } from './types';

interface GoodsRelateModalProps {
  callback?: (...arg: any[]) => any;
  onCancel?: (...arg: any[]) => any;
}

export const GoodsRelateModal: React.FC<GoodsRelateModalProps> = function({
  callback,
  onCancel,
}) {
  const goodsRelateModalStore = React.useMemo(() => new GoodsRelateModalStore(callback), []);

  return (
    <DragAndDropHOC>
      <Drawer
        maskClosable={false}
        onClose={onCancel}
        open
        title="手动关联"
        width="100%"
      >
        <div className={styles.container}>
          <div className={styles.box}>
            <OwnerGoods goodsRelateModalStore={goodsRelateModalStore}/>
            <StyleLibraryGoods goodsRelateModalStore={goodsRelateModalStore}/>
          </div>
        </div>
      </Drawer>
      <GoodsRelateSkuModal goodsRelateSkuModalStore={goodsRelateModalStore.goodsRelateSkuModalStore}/>
    </DragAndDropHOC>
  );
};

export const OwnerGoods: React.FC<{ goodsRelateModalStore: GoodsRelateModalStore; }> = observer(({ goodsRelateModalStore }) => {
  return (
    <div className={styles.left}>
      <h3>
        货主商品
      </h3>
      <div className={styles.programmeContainer}>
        <NormalProgrammeComponent store={goodsRelateModalStore.ownerGoodsStore.normalProgramme}/>
      </div>

      <div className={styles.contentContainer}>
        {
          goodsRelateModalStore.ownerGoodsStore.goodsData.length ? goodsRelateModalStore.ownerGoodsStore.goodsData.map((item) => (
            <GoodsItemDisplay
              className={item.productId && item.productId === goodsRelateModalStore.ownerGoodsStore.activeGoodsItem?.productId ? goodsItemDisplayStyles.active : ''}
              data={item}
              handleItemClick={goodsRelateModalStore.ownerGoodsStore.setActiveGoodsItem}
              key={item.productId}
              type={ENUM_GOODS_TYPE.ownerGoods}
            />
          )) : (
            <Empty
              description="暂无数据哦"
              image={getStaticResourceUrl('pc/ts/egenie-common/images/empty.png')}
            />
          )
        }
      </div>
    </div>
  );
});

export const StyleLibraryGoods: React.FC<{ goodsRelateModalStore: GoodsRelateModalStore; }> = observer(({ goodsRelateModalStore }) => {
  return (
    <RenderByCondition show={goodsRelateModalStore.showStyleLibraryGoods}>
      <div className={styles.right}>
        <h3>
          款式库商品
        </h3>
        <div className={styles.programmeContainer}>
          <NormalProgrammeComponent store={goodsRelateModalStore.styleLibraryGoodsStore.normalProgramme}/>
        </div>
        <div className={styles.contentContainer}>
          {
            goodsRelateModalStore.styleLibraryGoodsStore.goodsData.length ? goodsRelateModalStore.styleLibraryGoodsStore.goodsData.map((item) => (
              <GoodsItemDisplay
                data={item}
                handleGoodsRelate={goodsRelateModalStore.handleGoodsRelate}
                key={item.productId}
                showRelateButton
                type={ENUM_GOODS_TYPE.styleLibraryGoods}
              />
            )) : (
              <Empty
                description="暂无数据哦"
                image={getStaticResourceUrl('pc/ts/egenie-common/images/empty.png')}
              />
            )
          }
        </div>
      </div>
    </RenderByCondition>
  );
});
