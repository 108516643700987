import type { PaginationData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, computed } from 'mobx';
import type { GoodsRelateItem } from './types';
import { OwnerGoodsStore } from './ownerGoodsStore';
import { StyleLibraryGoodsStore } from './styleLibraryGoodsStore';
import { GoodsRelateSkuModalStore } from './goodsRelateSkuModal/goodsRelateSkuModalStore';

export async function getGoodsData(data): Promise<GoodsRelateItem[]> {
  const info = await request<PaginationData<GoodsRelateItem>>({
    url: '/api/cloud/baseinfo/rest/sku/relation/query/product',
    method: 'post',
    data: {
      ...data,
      page: 1,
      pageSize: 50,
    },
  });

  if (Array.isArray(info?.data?.list)) {
    return info.data.list;
  } else {
    return [];
  }
}

export class GoodsRelateModalStore {
  constructor(public callback?: (...arg: any) => any) {
  }

  public ownerGoodsStore: OwnerGoodsStore = new OwnerGoodsStore(this);

  public styleLibraryGoodsStore: StyleLibraryGoodsStore = new StyleLibraryGoodsStore();

  public goodsRelateSkuModalStore: GoodsRelateSkuModalStore = new GoodsRelateSkuModalStore(this);

  @computed
  public get showStyleLibraryGoods(): boolean {
    return Boolean(this.ownerGoodsStore.activeGoodsItem);
  }

  @action public handleGoodsRelate = (styleLibraryGoods: GoodsRelateItem): void => {
    this.goodsRelateSkuModalStore.handleOpen(this.ownerGoodsStore.activeGoodsItem, styleLibraryGoods);
  };
}
