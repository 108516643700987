import { action, observable } from 'mobx';
import type { GoodsRelateModalStore } from '../goodsRelateModalStore';
import type { GoodsRelateItem, SkuDetailItem, SkuSizeItem } from '../types';
import type { PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { nanoid } from 'nanoid';
import { message } from 'antd';

export async function getSkuData(data): Promise<SkuDetailItem[]> {
  const info = await request<PaginationData<SkuDetailItem>>({
    url: '/api/cloud/baseinfo/rest/sku/relation/query/sku',
    method: 'post',
    data: {
      ...data,
      page: 1,
      pageSize: 1000,
    },
  });

  return (Array.isArray(info?.data?.list) ? info.data.list : []).map((item) => {
    const sameColorSku: SkuSizeItem[] = Array.isArray(item.sameColorSku) ? item.sameColorSku : [];
    const relatedSku: SkuSizeItem[] = Array.isArray(item.relatedSku) ? item.relatedSku.slice() : [];

    // 处理已经设置对应关系,需要将关联的sku一一对应
    const relatedSkuMap = new Map<string, { index: number; data: SkuSizeItem; }>();
    relatedSku.forEach((val, index) => {
      relatedSkuMap.set(val.skuId, {
        index,
        data: val,
      });
    });
    const realRelatedSku: SkuSizeItem[] = [];

    // 筛选已经设置对应关系的sku
    sameColorSku.forEach((val) => {
      const relatedSkuItem = relatedSkuMap.get(val.relatedSkuId);
      if (val.relatedSkuId && relatedSkuItem) {
        realRelatedSku.push(relatedSkuItem.data);
        relatedSku[relatedSkuItem.index] = null;
      }
    });

    // 没有绑定的按顺序
    relatedSku.forEach((val) => {
      if (val) {
        realRelatedSku.push(val);
      }
    });

    return {
      ...item,
      _id: nanoid(),
      sameColorSku,
      relatedSku: realRelatedSku,
    };
  });
}

export class GoodsRelateSkuModalStore {
  constructor(private parent: GoodsRelateModalStore) {
  }

  @observable.ref public ownerGoods: GoodsRelateItem = null;

  @observable.ref public styleLibraryGoods: GoodsRelateItem = null;

  @observable public ownerSkuData: SkuDetailItem[] = [];

  @observable public styleLibrarySkuData: SkuDetailItem[] = [];

  @observable public open = false;

  @action public handleOpen = (ownerGoods: GoodsRelateItem, styleLibraryGoods: GoodsRelateItem): void => {
    this.open = true;
    this.ownerGoods = ownerGoods;
    this.styleLibraryGoods = styleLibraryGoods;

    getSkuData({ productId: ownerGoods.productId })
      .then((data) => this.ownerSkuData = data);
    getSkuData({ productId: styleLibraryGoods.productId })
      .then((data) => this.styleLibrarySkuData = data);
  };

  @action public handleClose = (): void => {
    this.open = false;
    this.ownerGoods = null;
    this.styleLibraryGoods = null;
    this.ownerSkuData = [];
    this.styleLibrarySkuData = [];
  };

  @observable public isSubmit = false;

  @action public handleOwnerSkuAcceptDrop = (ownerSkuData: SkuDetailItem, styleLibraryGoods: SkuDetailItem): void => {
    ownerSkuData.relatedPic = styleLibraryGoods.pic;
    ownerSkuData.relatedColor = styleLibraryGoods.color;

    // 拖拽自动处理尺寸一致的size
    const ownerSizeData = ownerSkuData.sameColorSku;
    const styleLibrarySizeData = styleLibraryGoods.sameColorSku.slice();
    const realStyleLibrarySizeData: SkuSizeItem[] = [];

    // 这里尺寸考虑重复和不存在的情况,不使用map降低复杂度
    for (let i = 0; i < ownerSizeData.length; i++) {
      const matchSizeIndex = styleLibrarySizeData.findIndex((item) => item && item.size && item.size === ownerSizeData[i].size);
      if (matchSizeIndex !== -1) {
        realStyleLibrarySizeData.push(styleLibrarySizeData[matchSizeIndex]);
        styleLibrarySizeData[matchSizeIndex] = null;
      }
    }

    // 剩余不匹配的处理
    for (let i = 0; i < styleLibrarySizeData.length; i++) {
      if (styleLibrarySizeData[i]) {
        realStyleLibrarySizeData.push(styleLibrarySizeData[i]);
      }
    }

    ownerSkuData.relatedSku = realStyleLibrarySizeData;
  };

  @action public handleOwnerSkuRelateRemove = (ownerSkuData: SkuDetailItem): void => {
    ownerSkuData.relatedPic = null;
    ownerSkuData.relatedColor = null;
    ownerSkuData.relatedSku = [];
  };

  @action public handleSubmit = () => {
    const data: Array<{ skuId: string; relatedSkuId: string; }> = [];

    this.ownerSkuData.forEach((item) => {
      const commonMatchLength = Math.min(item.sameColorSku.length, item.relatedSku.length);
      for (let i = 0; i < commonMatchLength; i++) {
        data.push({
          skuId: item.sameColorSku[i].skuId,
          relatedSkuId: item.relatedSku[i].skuId,
        });
      }
    });

    if (!data.length) {
      const error = '没有可关联的数据';
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    this.isSubmit = true;
    request({
      url: '/api/cloud/baseinfo/rest/sku/relation/bind',
      method: 'post',
      data,
    })
      .then(() => {
        this.handleClose();
        if (typeof this.parent.callback === 'function') {
          this.parent.callback();
        }
      })
      .finally(() => this.isSubmit = false);
  };
}
