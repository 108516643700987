import type { PopoverProps } from 'antd';
import { Popover } from 'antd';
import { request, MainSubStructure, MainSubStructureModel, TimeStampFormatter } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';

interface LogTooltipProps {
  popoverProps?: PopoverProps;
  width?: number;
  height?: number;
  entityId: number | string;
  logBizModuleCode: string | number;
  extraParams?: any;
}

class TableWrapper extends React.Component<LogTooltipProps> {
  constructor(props: LogTooltipProps) {
    super(props);
    this.gridModel = new MainSubStructureModel({
      grid: {
        columns: [
          {
            key: 'moduleType',
            name: '操作模块',
            width: 100,
          },
          {
            key: 'operationType',
            name: '操作名称',
            width: 100,
          },
          {
            key: 'operationResult',
            name: '操作结果',
          },
          {
            key: 'createTime',
            name: '操作时间',
            width: 200,
            formatter: ({ row }) => {
              return <TimeStampFormatter value={row.createTime}/>;
            },
          },
          {
            key: 'operatorShowName',
            name: '操作人',
            width: 100,
          },
        ].map((info) => ({
          resizable: true,
          sortable: false,
          ...info,
        })),
        rows: [],
        primaryKeyField: 'logBizRecordId',
        sortByLocal: false,
        showCheckBox: false,
        showEmpty: false,
        showReset: false,
        showSelectedTotal: false,
      },

      // 主表查询api
      api: {
        onQuery: (params) => {
          const {
            filterParams = {},
            ...rest
          } = params;

          return request({
            url: '/api/cloud/log/listPage',
            method: 'POST',
            data: {
              ...rest,
              logBizModuleCode: props.logBizModuleCode,
              entityId: props.entityId,
              ...props.extraParams,
            },
          });
        },
      },
      hiddenSubTable: true,
    });
  }

  componentDidMount() {
    this.gridModel.onQuery();
  }

  public gridModel: MainSubStructureModel;

  render() {
    const {
      width,
      height,
    } = this.props;
    return (
      <div
        style={{
          width,
          height,
        }}
      >
        <MainSubStructure store={this.gridModel}/>
      </div>
    );
  }
}

@observer
export class LogTooltip extends React.Component<LogTooltipProps> {
  render() {
    const {
      popoverProps,
      width,
      height,
      entityId,
      logBizModuleCode,
      children,
      extraParams,
    } = this.props;
    return (
      <Popover
        children={children}
        content={() => (
          <TableWrapper
            entityId={entityId}
            extraParams={extraParams}
            height={height}
            logBizModuleCode={logBizModuleCode}
            width={width}
          />
        )}
        destroyTooltipOnHide
        overlayStyle={{ border: 'none' }}
        trigger="click"
        {...popoverProps}
      />
    );
  }
}
