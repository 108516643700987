import { Card, Layout } from 'antd';
import type { NormalProgramme, MainSubStructureModel } from 'egenie-utils';
import { MainSubStructure, NormalProgrammeComponent } from 'egenie-utils';
import React from 'react';
import styles from './index.less';
import { mainSubStructureModel } from './mainSubStructureModel';
import { normalProgramme } from './normalProgramme';
import { getOwner } from '../../utils';

export class Store {
  constructor() {
    this.mainSubStructureModel = mainSubStructureModel(this);

    this.normalProgramme = normalProgramme(this);

    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;

    getOwner()
      .then((ownerIds) => this.normalProgramme.filterItems.addDict({ ownerIds }));
  }

  public mainSubStructureModel: MainSubStructureModel;

  public normalProgramme: NormalProgramme;
}

export default function() {
  const store = React.useMemo(() => new Store(), []);

  return (
    <Layout className={styles.container}>
      <Layout.Content>
        <Card size="small">
          <NormalProgrammeComponent store={store.normalProgramme}/>
        </Card>
        <div className={styles.tableWrapper}>
          <MainSubStructure store={store.mainSubStructureModel}/>
        </div>
      </Layout.Content>
    </Layout>
  );
}
