import { NormalProgramme } from 'egenie-utils';
import type { Store } from './index';

export function normalProgramme(context: Store): NormalProgramme {
  return new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'relatedSkuNo',
        label: '款式库SKU编码',
      },
      {
        type: 'input',
        field: 'skuNo',
        label: '货主SKU编码',
      },
      {
        type: 'input',
        field: 'sellerOuterNo',
        label: '货主商品货号',
      },
      {
        type: 'select',
        field: 'ownerIds',
        mode: 'multiple',
        label: '货主',
      },
    ],
    count: 6,
    handleSearch: () => context.mainSubStructureModel.onQuery(),
  });
}
