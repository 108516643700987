import { Button, Typography } from 'antd';
import React from 'react';
import styles from './goodsItemDisplay.less';
import { observer } from 'mobx-react';
import { egeniePcTheme, Image, RenderByCondition } from 'egenie-common';
import { ENUM_GOODS_TYPE } from '../types';
import type { GoodsRelateItem } from '../types';
import classnames from 'classnames';

export interface GoodsItemDisplayProps {
  className?: string;
  style?: React.CSSProperties;
  data: GoodsRelateItem;
  handleItemClick?: (data?: GoodsRelateItem) => any;
  showRelateButton?: boolean;
  handleGoodsRelate?: (data?: GoodsRelateItem) => any;
  type: ENUM_GOODS_TYPE;
}

export const GoodsItemDisplay: React.FC<GoodsItemDisplayProps> = observer(({
  className = '',
  style = {},
  data,
  handleItemClick,
  showRelateButton = false,
  handleGoodsRelate,
  type,
}) => {
  return (
    <section
      className={classnames(styles.goodsItem, className)}
      onClick={() => {
        if (typeof handleItemClick === 'function') {
          handleItemClick(data);
        }
      }}
      style={{
        ...style,
        ...(showRelateButton ? { paddingRight: 60 } : {}),
      }}
    >
      <div
        style={{
          width: 88,
          height: 88,
        }}
      >
        <Image
          alt=""
          height={88}
          src={data?.pic}
          width={88}
        />
      </div>
      <section>
        <Typography.Title
          ellipsis
          level={3}
          title={data?.productName}
        >
          商品名称:&nbsp;
          {data?.productName}
        </Typography.Title>
        <Typography.Title
          ellipsis
          level={3}
          style={{ color: egeniePcTheme.color['egenie-text-color'] }}
          title={data?.productNo}
        >
          商品编码:&nbsp;
          {data?.productNo}
        </Typography.Title>
        <Typography.Title
          ellipsis
          level={3}
          style={{ color: egeniePcTheme.color['egenie-text-color'] }}
          title={data?.sellerOuterNo}
        >
          {type === ENUM_GOODS_TYPE.ownerGoods ? '货号' : '档口款式货号'}
          :&nbsp;
          {data?.sellerOuterNo}
        </Typography.Title>
        <RenderByCondition show={type === ENUM_GOODS_TYPE.styleLibraryGoods}>
          <Typography.Title
            ellipsis
            level={3}
            style={{ color: egeniePcTheme.color['egenie-text-color'] }}
            title={data?.vendorNo}
          >
            供应商:&nbsp;
            {data?.vendorNo}
          </Typography.Title>
        </RenderByCondition>
      </section>
      <RenderByCondition show={showRelateButton}>
        <div className={styles.btnContainer}>
          <Button
            onClick={() => handleGoodsRelate(data)}
            size="small"
            type="primary"
          >
            去关联
          </Button>
        </div>
      </RenderByCondition>
    </section>
  );
});
