import React from 'react';
import { observer } from 'mobx-react';
import type { SkuDetailItem, SkuSizeItem } from '../types';
import styles from './skuDetailDisplay.less';
import { Typography } from 'antd';
import { Image, RenderByCondition } from 'egenie-common';
import classnames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import { toJS } from 'mobx';

export interface SkuDetailDisplayProps {
  className?: string;
  style?: React.CSSProperties;
  data: SkuDetailItem;
  type: SKU_DETAIL_DISPLAY_TYPE;
}

export enum SKU_DETAIL_DISPLAY_TYPE {
  pureRender,
  styleLibraryGoodsDrag,
  ownerGoodsSkuDrag
}

interface SizeDragAndDropProps {
  id: string;
  item: SkuSizeItem;
  type: SKU_DETAIL_DISPLAY_TYPE;
  index: number;
  sizeData: SkuSizeItem[];
}

const SizeDragAndDrop: React.FC<SizeDragAndDropProps> = observer(({
  item,
  type,
  index,
  sizeData,
  id,
}) => {
  function moveRow(dragIndex: number, hoverIndex: number): void {
    const tmp = sizeData[dragIndex];
    sizeData.splice(dragIndex, 1);
    sizeData.splice(hoverIndex, 0, tmp);
  }

  const dragType = id;
  const ref = React.useRef();
  const [
    {
      isOver,
      dropClassName,
    },
    drop,
  ] = useDrop({
    accept: dragType,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ` ${styles.dropOverDownward}` : ` ${styles.dropOverUpward}`,
      };
    },
    drop: (item) => {
      moveRow((item as any as { index: number; }).index, index);
    },
  });

  const [, drag] = useDrag({
    item: {
      type: dragType,
      index,
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });
  drop(drag(ref));

  return (
    <section
      className={classnames(styles.sizeContainer, isOver && type === SKU_DETAIL_DISPLAY_TYPE.ownerGoodsSkuDrag ? dropClassName : '')}
      ref={type === SKU_DETAIL_DISPLAY_TYPE.ownerGoodsSkuDrag ? ref : null}
      style={{ cursor: type === SKU_DETAIL_DISPLAY_TYPE.ownerGoodsSkuDrag ? 'move' : undefined }}
    >
      <RenderByCondition show={type !== SKU_DETAIL_DISPLAY_TYPE.pureRender}>
        <i className="icon-icon-px01"/>
      </RenderByCondition>
      <Typography.Text
        ellipsis
        title={item?.size}
      >
        {item?.size}
      </Typography.Text>
    </section>
  );
});

export const SKU_DETAIL_DRAG_TYPE = 'SKU_DETAIL_DRAG_TYPE';

export const SkuDetailDisplay: React.FC<SkuDetailDisplayProps> = observer(({
  className = '',
  style = {},
  data,
  type,
}) => {
  const isOwnerGoodsSkuDrag = type === SKU_DETAIL_DISPLAY_TYPE.ownerGoodsSkuDrag;
  const sizeData = (isOwnerGoodsSkuDrag ? data?.relatedSku : data?.sameColorSku) || [];
  const [, dragRef] = useDrag({
    item: {
      type: SKU_DETAIL_DRAG_TYPE,
      data: toJS(data),
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  return (
    <div
      className={classnames(styles.skuDetailDisplayContainer, className)}
      ref={type === SKU_DETAIL_DISPLAY_TYPE.styleLibraryGoodsDrag ? dragRef : null}
      style={{
        ...style,
        ...(type === SKU_DETAIL_DISPLAY_TYPE.styleLibraryGoodsDrag ? { cursor: 'move' } : {}),
      }}
    >
      <div className={styles.left}>
        <div className={styles.colorContainer}>
          <RenderByCondition show={type === SKU_DETAIL_DISPLAY_TYPE.styleLibraryGoodsDrag}>
            <i className="icon-icon-px01"/>
          </RenderByCondition>
          <Typography.Text
            ellipsis
            title={isOwnerGoodsSkuDrag ? data?.relatedColor : data?.color}
          >
            {isOwnerGoodsSkuDrag ? data?.relatedColor : data?.color}
          </Typography.Text>
          <Image
            height={30}
            src={isOwnerGoodsSkuDrag ? data?.relatedPic : data?.pic}
            width={32}
          />
        </div>
      </div>
      <div className={styles.right}>
        {
          sizeData.map((item, index) => (
            <SizeDragAndDrop
              id={data?._id}
              index={index}
              item={item}
              key={item.skuId}
              sizeData={sizeData}
              type={type}
            />
          ))
        }
      </div>
    </div>
  );
});
