import { NormalProgramme } from 'egenie-utils';
import { action, observable } from 'mobx';
import type { GoodsRelateItem } from './types';
import { getAllVendor } from '../../../utils';
import { getGoodsData } from './goodsRelateModalStore';
import { ENUM_GOODS_TYPE } from './types';

export class StyleLibraryGoodsStore {
  constructor() {
    getAllVendor()
      .then((data) => this.normalProgramme.filterItems.addDict({ vendorIds: data }));
  }

  public normalProgramme: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'productNo',
        label: '商品编码',
        labelWidth: 0,
        placeholder: '商品编码',
        isMultipleSearch: true,
      },
      {
        type: 'input',
        field: 'sellerOuterNo',
        label: '货号',
        labelWidth: 0,
        placeholder: '请输入货号',
      },
      {
        type: 'select',
        field: 'vendorIds',
        label: '供应商',
        mode: 'multiple',
        labelWidth: 0,
        placeholder: '请输入供应商',
      },
    ],
    handleSearch: () => this.getData(),
    count: 4,
  });

  @action public getData = async() => {
    this.goodsData = await getGoodsData({
      ...this.normalProgramme.filterItems.params,
      ownerType: ENUM_GOODS_TYPE.styleLibraryGoods,
    });
  };

  @observable public goodsData: GoodsRelateItem[] = [];
}

