import { NormalProgramme } from 'egenie-utils';
import { action, observable } from 'mobx';
import type { GoodsRelateItem } from './types';
import { getOwner } from '../../../utils';
import type { GoodsRelateModalStore } from './goodsRelateModalStore';
import { getGoodsData } from './goodsRelateModalStore';
import { ENUM_GOODS_TYPE } from './types';

export class OwnerGoodsStore {
  constructor(private parent: GoodsRelateModalStore) {
    getOwner()
      .then((data) => this.normalProgramme.filterItems.addDict({ ownerIds: data }));
  }

  public normalProgramme: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'productNo',
        label: '商品编码',
        labelWidth: 0,
        placeholder: '请输入商品编码',
      },
      {
        type: 'input',
        field: 'sellerOuterNo',
        label: '货号',
        labelWidth: 0,
        placeholder: '请输入货号',
      },
      {
        type: 'select',
        field: 'ownerIds',
        label: '货主',
        mode: 'multiple',
        labelWidth: 0,
        placeholder: '请输入货主',
      },
    ],
    handleSearch: () => this.getData(),
    count: 4,
  });

  @action public getData = async() => {
    this.goodsData = await getGoodsData({
      ...this.normalProgramme.filterItems.params,
      ownerType: ENUM_GOODS_TYPE.ownerGoods,
    });

    this.activeGoodsItem = null;
  };

  @observable public goodsData: GoodsRelateItem[] = [];

  @observable.ref public activeGoodsItem: GoodsRelateItem = null;

  @action public setActiveGoodsItem = (activeGoodsItem: GoodsRelateItem): void => {
    this.activeGoodsItem = activeGoodsItem;
    if (activeGoodsItem.relatedProductNoList && activeGoodsItem.relatedProductNoList.length) {
      const styleLibraryGoodsStore = this.parent.styleLibraryGoodsStore;
      styleLibraryGoodsStore.normalProgramme.filterItems.reset();
      styleLibraryGoodsStore.goodsData = [];
      styleLibraryGoodsStore.normalProgramme.filterItems.updateFilterItem([
        {
          type: 'input',
          field: 'productNo',
          value: activeGoodsItem.relatedProductNoList.join(','),
        },
      ]);
      styleLibraryGoodsStore.getData();
    }
  };
}

