import type { PaginationData } from 'egenie-utils';
import { ImgFormatter, MainSubStructureModel, request } from 'egenie-utils';
import React from 'react';
import type { MainItem } from './types';
import type { Store } from './index';
import { LogTooltip } from '../../utils';
import { message, Modal } from 'antd';
import { GoodsRelateModal } from './goodsRelateModal/goodsRelateModal';
import { destroyAllModal, renderModal } from 'egenie-common';

export function mainSubStructureModel(context: Store): MainSubStructureModel {
  return new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          frozen: true,
          width: 50,
          formatter: ({ row }) => (
            <LogTooltip
              children={(
                <a>
                  日志
                </a>
              )}
              entityId={row.skuRelationId}
              height={230}
              logBizModuleCode={191000}
              width={810}
            />
          ),
        },
        {
          key: 'mark',
          name: '标记',
          width: 80,
        },
        {
          key: 'productNo',
          name: '货主商品编码',
          width: 250,
        },
        {
          key: 'sellerOuterNo',
          name: '货主商品货号',
          width: 250,
        },
        {
          key: 'skuNo',
          name: '货主SKU编码',
          width: 250,
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'color',
          name: '颜色',
          width: 80,
        },
        {
          key: 'size',
          name: '尺码',
          width: 80,
        },
        {
          key: 'ownerName',
          name: '货主名称',
          width: 250,
        },
        {
          key: 'relatedSkuNo',
          name: '款式库SKU编码',
          width: 250,
        },
        {
          key: 'relatedSkuPic',
          name: '款式库图片',
          width: 80,
          formatter: ({ row }) => <ImgFormatter value={row.relatedSkuPic}/>,
        },
        {
          key: 'createTypeDesc',
          name: '创建方式',
          width: 100,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'skuRelationId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/skuRelevance/index',
    },
    buttons: [
      {
        permissionId: '6021',
        text: '创建',
        handleClick: () => {
          renderModal((
            <GoodsRelateModal
              callback={() => {
                context.mainSubStructureModel.onQuery();
                destroyAllModal();
              }}
              onCancel={destroyAllModal}
            />
          ));
        },
      },
      {
        permissionId: '6022',
        text: '解绑',
        handleClick: () => {
          const skuRelationIds = Array.from(context.mainSubStructureModel.gridModel.selectedIds);
          if (skuRelationIds.length === 0) {
            const error = '请至少选择一条数据';
            message.warning({
              key: error,
              content: error,
            });
            return;
          }

          Modal.confirm({
            content: '确认解绑?',
            onOk: () => request({
              url: '/api/cloud/baseinfo/rest/sku/relation/unbind',
              method: 'post',
              data: { skuRelationIds },
            })
              .then(() => {
                message.success('解绑成功');
                context.mainSubStructureModel.gridModel.clearToOriginal();
                context.mainSubStructureModel.onRefresh();
              }),
          });
        },
      },
    ],
    pageId: '60207',

    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        return request<PaginationData<MainItem>>({
          url: '/api/cloud/baseinfo/rest/sku/relation/page',
          method: 'POST',
          data: {
            ...rest,
            ...filterParams,
          },
        });
      },
    },
    hiddenSubTable: true,
  });
}
