import { Button, Drawer, Space, Row, Col } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';
import type { GoodsRelateSkuModalStore } from './goodsRelateSkuModalStore';
import styles from './goodsRelateSkuModal.less';
import { GoodsItemDisplay } from '../goodsItemDisplay/goodsItemDisplay';
import type { SkuDetailItem } from '../types';
import { ENUM_GOODS_TYPE } from '../types';
import { egeniePcTheme, RenderByCondition } from 'egenie-common';
import { SKU_DETAIL_DISPLAY_TYPE, SKU_DETAIL_DRAG_TYPE, SkuDetailDisplay } from './skuDetailDisplay';
import classnames from 'classnames';
import { useDrop } from 'react-dnd';

export const GoodsRelateSkuModal: React.FC<{ goodsRelateSkuModalStore: GoodsRelateSkuModalStore; }> = observer(({ goodsRelateSkuModalStore }) => {
  const {
    handleClose,
    open,
    isSubmit,
    handleSubmit,
  } = goodsRelateSkuModalStore;
  return (
    <Drawer
      maskClosable={false}
      onClose={handleClose}
      open={open}
      title={(
        <Row
          align="middle"
          justify="space-between"
        >
          <span>
            SKU关联
          </span>
          <Space>
            <Button onClick={handleClose}>
              取消
            </Button>
            <Button
              loading={isSubmit}
              onClick={handleSubmit}
              type="primary"
            >
              确定
            </Button>
          </Space>
        </Row>
      )}
      width="100%"
    >
      <div className={styles.goodsRelateSkuModalContainer}>
        <div className={styles.box}>
          <header>
            <i className="icon-note_zs"/>
            可拖拽款式库商品与货主商品匹配,尺码可上下拖动排序
          </header>
          <Row className={styles.titleContainer}>
            <h3>
              货主商品
            </h3>
            <h3>
              款式库商品
            </h3>
          </Row>
          <div className={styles.boxWrapper}>
            <OwnerSkuDetail goodsRelateSkuModalStore={goodsRelateSkuModalStore}/>
            <StyleLibrarySkuDetail goodsRelateSkuModalStore={goodsRelateSkuModalStore}/>
          </div>
        </div>
      </div>
    </Drawer>
  );
});

const resetGoodsStyle = {
  border: 'none',
  backgroundColor: egeniePcTheme.color['egenie-background-bg'],
  flexShrink: 0,
  marginBottom: 0,
};

const OwnerSkuDetail: React.FC<{ goodsRelateSkuModalStore: GoodsRelateSkuModalStore; }> = observer(({ goodsRelateSkuModalStore }) => {
  const {
    ownerGoods,
    ownerSkuData,
  } = goodsRelateSkuModalStore;

  return (
    <div className={styles.left}>
      <GoodsItemDisplay
        data={ownerGoods}
        style={resetGoodsStyle}
        type={ENUM_GOODS_TYPE.ownerGoods}
      />
      <div className={styles.ownerSizeDetailContainer}>
        {
          ownerSkuData.map((item) => {
            return (
              <Row
                gutter={[
                  64,
                  8,
                ]}
                key={item._id}
                style={{ marginBottom: egeniePcTheme.spacing['egenie-spacing-xs'] }}
              >
                <Col span={12}>
                  <SkuDetailDisplay
                    data={item}
                    type={SKU_DETAIL_DISPLAY_TYPE.pureRender}
                  />
                </Col>
                <Col span={12}>
                  <OwnerSkuAcceptDropContainer
                    data={item}
                    goodsRelateSkuModalStore={goodsRelateSkuModalStore}
                  />
                </Col>
              </Row>
            );
          })
        }
      </div>
    </div>
  );
});

const OwnerSkuAcceptDropContainer: React.FC<{ goodsRelateSkuModalStore: GoodsRelateSkuModalStore; data: SkuDetailItem; }> = observer(({
  goodsRelateSkuModalStore: {
    handleOwnerSkuAcceptDrop,
    handleOwnerSkuRelateRemove,
  },
  data,
}) => {
  const hasRelatedGoods = data.relatedColor && data.relatedSku.length > 0;

  const [, dropRef] = useDrop({
    accept: SKU_DETAIL_DRAG_TYPE,
    drop: (item) => {
      handleOwnerSkuAcceptDrop(data, (item as any as { data: SkuDetailItem; }).data);
    },
  });

  return (
    <div
      className={classnames(styles.relateSkuDropContainer, {
        [styles.emptySkuContainer]: !hasRelatedGoods,
        [styles.notEmptySkuContainer]: hasRelatedGoods,
      })}
      ref={dropRef}
    >
      <img
        className={styles.linkContainer}
        height={32}
        src={require('../../../../assets/images/link.png')}
        width={32}
      />
      {
        hasRelatedGoods ? (
          <SkuDetailDisplay
            data={data}
            type={SKU_DETAIL_DISPLAY_TYPE.ownerGoodsSkuDrag}
          />
        ) : '请将衫海精商品拖入匹配'
      }
      <RenderByCondition show={hasRelatedGoods}>
        <i
          className="icon-delete"
          onClick={() => handleOwnerSkuRelateRemove(data)}
        />
      </RenderByCondition>
    </div>
  );
});

const StyleLibrarySkuDetail: React.FC<{ goodsRelateSkuModalStore: GoodsRelateSkuModalStore; }> = observer(({
  goodsRelateSkuModalStore: {
    styleLibraryGoods,
    styleLibrarySkuData,
  },
}) => {
  return (
    <div className={styles.right}>
      <GoodsItemDisplay
        data={styleLibraryGoods}
        style={resetGoodsStyle}
        type={ENUM_GOODS_TYPE.styleLibraryGoods}
      />
      <div className={styles.styleLibrarySizeDetailContainer}>
        {
          styleLibrarySkuData.map((item) => (
            <SkuDetailDisplay
              data={item}
              key={item._id}
              style={{ marginBottom: egeniePcTheme.spacing['egenie-spacing-xs'] }}
              type={SKU_DETAIL_DISPLAY_TYPE.styleLibraryGoodsDrag}
            />
          ))
        }
      </div>
    </div>
  );
});
