export interface GoodsRelateItem {
  productId?: string;
  productName?: string;
  productNo?: string;
  sellerOuterNo?: string;
  vendorNo?: string;
  pic?: string;
  relatedProductNoList?: string[];
}

export interface SkuSizeItem {
  size?: string;
  skuId?: string;
  relatedSkuId?: string;
}

export interface SkuDetailItem {
  _id?: string;
  color?: string;
  pic?: string;
  sameColorSku?: SkuSizeItem[];
  relatedColor?: string;
  relatedPic?: string;
  relatedSku?: SkuSizeItem[];
}

export enum ENUM_GOODS_TYPE {
  ownerGoods = 1,
  styleLibraryGoods = 2
}
